import React, { Component } from 'react'
// import styled from 'styled-components';

import SEO from '../components/SEO/SEO'
import Layout from '../Layout/Layout'
import Hero from '../components/Hero/Hero'
import Header from '../components/Header/Header'
import Subheader from '../components/Subheader/Subheader'
import Paragraph from '../components/Paragraph/Paragraph'
import Container from '../components/Container/Container'
import EqImage from '../components/EqImage/EqImage'

import Image1 from '../assets/images/zwyzka-teleskopowa-helios.jpg'
import Image2 from '../assets/images/zwyzka-teleskopowa-agata-meble-szyldy-reklamowe.jpg'
import Image3 from '../assets/images/zwyzka-teleskopowa-wycinka-drzew.jpg'
import Image4 from '../assets/images/pomoc-drogowa.jpeg'
import Image5 from '../assets/images/pomoc-drogowa-holowanie.jpeg'
import Image6 from '../assets/images/podnosnik-koszowy-rzeszow.jpg'
// import Image7 from "../assets/images/podnosnik-koszowy-podkarpackie.jpg";
import Image8 from '../assets/images/nozycowka.jpg'

import Thumb1 from '../assets/images/thumb1.jpg'
import Thumb2 from '../assets/images/thumb2.jpg'
import Thumb3 from '../assets/images/thumb3.jpg'
import Thumb4 from '../assets/images/thumb4.jpg'
import Thumb5 from '../assets/images/thumb5.jpg'
import Thumb6 from '../assets/images/thumb6.jpg'

class UslugiPage extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Usługi | Zobacz co oferujemy dla naszych klientów"
          keywords={['usługi']}
        />
        <Hero image={Image6}>
          <Header>Usługi</Header>
          <Subheader>Oferta skierowana do firm i osób prywatnych</Subheader>
        </Hero>
        <Header as="h3">
          Wynajem podnośnika koszowego wraz z operatorem
        </Header>
        <Container columns={2}>
          <Paragraph as="article">
            <p>
              Oferujemy wynajem zwyżki koszowej wraz z operatorem do
              wszelakich prac budowlanych i ogrodowych. Sprawnie, szybko i
              elastycznie zrealizujemy każde zlecenie. Podejmiemy się prac
              na wysokościach, takich jak: wycinka drzew, czyszczenie
              rynien, montowanie bilboardów/reklam, czy usuwanie
              zagrażających przechodniom sopli i śniegu.
            </p>
            <p>
              Jesteśmy wstanie wjechać tam gdzie inne podnośniki o podobnych
              parametrach nie dają rady poza tym konstrukcja przegubowo
              teleskopowa umożliwia pracę nad wysuniętymi dachami, daszkami
              itp.
            </p>
            <p>
              Usługi wykonujemy wykorzystując własne podnośniki koszowe
              przez co nasze ceny są konkurencyjne.
            </p>
          </Paragraph>
          <EqImage image={Image1} alt="" />
        </Container>

        <Header as="h3">Odśnieżanie dachów</Header>
        <Container columns={2}>
          <EqImage
            image={Thumb3}
            alt="Zwyżka stojąca przy bloku mieszkalnym, operator w koszu na wysokości dachu"
          />
          <Paragraph as="article">
            <p>
              Odśnieżanie dachu i usuwanie sopli należy do obowiązków
              właściciela bądź zarządcy nieruchomości. Obowiązek odśnieżania
              dachu jest opisany w Prawie budowlanym, a nadzór budowlany i
              straż miejska przywiązują dużą wagę do odśnieżania dachów
              zimą.
            </p>
            <p>
              Ważnym obowiązkiem właścicieli i zarządców nieruchomości jest
              odśnieżanie dachów budynków. Śnieg zalegający na powierzchni
              dachu może negatywnie wpłynąć na konstrukcję dachu, zwłaszcza
              w czasie roztopów i wahań temperatury. Śnieg zalegający na
              dachu może również stanowić zagrożenie dla przechodniów oraz
              samochodów poruszających się po drogach osiedlowych. Z tego
              powodu Prawo budowlane nakłada obowiązek usuwania nadmiaru
              śniegu z dachu oraz wykonywania doraźnej kontroli stanu
              technicznego dachu oraz całego budynku.
            </p>
            <p>
              Podobnie spadające sople czy nawisy śnieżne zagrażają
              bezpieczeństwu ludzi i mienia. Dlatego tak ważne jest
              odśnieżanie dachów zimą, do czego nadzór budowlany i straż
              miejska, policja przywiązują dużą wagę.
            </p>
          </Paragraph>
        </Container>

        <Header as="h3">
          Przycinanie, wycinka, pielęgnacja drzew ze zwyżki
        </Header>
        <Container columns={2}>
          <Paragraph as="article">
            <p>
              Oferujemy usługi przycinania oraz wycinki drzew z podnośnika
              koszowego 19 m wykonywane przez doświadczonych pracowników.
            </p>
            <p>
              Dysponujemy sprzętem posiadającym aktualne badania Urzędu
              Dozoru Technicznego.
            </p>
            <p>
              Wycinka drzew i gałęzi jest wana nie tylko z powodów
              estetycznych, ale prowadzi równie do likwidowania zagrożeń i
              kolizji wynikających ze współistnienia ludzi i drzew.
            </p>
            <p>
              Oferujemy usługi z zakresu ścinania, przycinania, wycinania
              drzew, w trudnych warunkach (na cmentarzach, przy linach
              wysokiego napięcia itp.).
            </p>
          </Paragraph>
          <EqImage image={Image3} alt="" />
        </Container>

        <Header as="h3">Prace na wysokościach</Header>
        <Container columns={2}>
          <EqImage image={Image2} alt="" />
          <EqImage image={Thumb4} alt="" />
        </Container>

        <Header as="h3">Pomoc drogowa</Header>
        <Container columns={2}>
          <EqImage image={Image5} alt="" />
          <EqImage image={Image4} alt="" />
        </Container>

        <Header as="h3">Usługi hydrauliczne</Header>
        <Container columns={2}>
          <EqImage image={Image6} alt="" />
          <EqImage image={Thumb2} alt="" />
        </Container>

        <Header as="h3">Wynajem maszyn budowlanych</Header>
        <Container columns={2}>
          <EqImage image={Thumb1} alt="" />
          <EqImage image={Thumb6} alt="" />
        </Container>
      </Layout>
    )
  }
}

export default UslugiPage
